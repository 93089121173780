<script lang="ts">

export interface BooleanBadgeProps {
  value: boolean
}
</script>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<BooleanBadgeProps>()

const display = computed(() => props.value ? 'YES' : 'NO')
</script>

<template>
  <span class="tag" :class="value ? 'is-success' : 'is-danger'">{{ display }}</span>
</template>
