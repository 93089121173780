import client from "./client";
import type { QuickEditSaveOptions } from "@/types/quickedits";
import type { Listing } from "@/types/listing";

const update = async (
	id: number,
	path: string,
	options: QuickEditSaveOptions[]
) => {
	const response = await client.post(
		`/organisations/${id}/listingsedit/${path}`,
		options
	);
	return response.data;
};

const updateList = async (id: number, listings: Listing[]) => {
	const response = await client.put(`/organisations/${id}/listings`, listings);
	return response.data;
};

export { update, updateList };
