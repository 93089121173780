<script lang="ts" setup>
import { Dial, DialShelf } from '@dailycare-au/visualise'
import { SkeletonLoader, Skeleton, TooltipProvider } from '@dailycare-au/ui'
import { asyncComputed } from '@vueuse/core'
import { ref } from 'vue'
import { organisationStore } from '@/stores/organisation'
import { storeToRefs } from 'pinia'
import { getReportOverview, getReportOverviewCSV, useFileDownload } from '@/api/reports.api'
import { useReportFilters } from './filters'

const { organisation } = storeToRefs(organisationStore())
const { period } = useReportFilters()

const loading = ref(false)
const overview = asyncComputed(async () => {
  const { data } = await getReportOverview(organisation.value!.id, period.value, 'this')
  return data
}, [], { evaluating: loading })

const { download } = useFileDownload(() => getReportOverviewCSV(organisation.value!.id, period.value, 'this'))
</script>

<template>
  <TooltipProvider :delay-duration="500">
    <div class="w-full col-span-full mt-4">
      <slot name="header">
        <div class="level">
          <h3 class="subtitle is-3 level-left mb-0">Overview</h3>
          <div class="level-right">
            <app-action-button class="is-text gtm-download-overview level-item" @click="download">
              <i class="fas fa-arrow-alt-to-bottom"></i>
            </app-action-button>
          </div>
        </div>
      </slot>

      <DialShelf class="col-span-full dials-shelf">
        <SkeletonLoader :count="5" :loading="loading">
          <Dial
            v-for="item in overview"
            :key="item.label"
            :value="item.value"
            :title="item.label"
            :description="item.description"
            :prev="item.previous_value"
            :value-suffix="item.suffix"
            :invert="item.invert"
          />

          <template #skeleton>
            <Skeleton class="h-40 w-full rounded-xl" />
          </template>
        </SkeletonLoader>
      </DialShelf>
    </div>
  </TooltipProvider>
</template>