import careTypes from "./careTypes";
import diverseNeeds from "./diverse.needs";
import QuickEdits from "./quickedits";
import lockdown from "./lockdown";
import respite from "./respite";
import { australianStates } from "../locations";
import listingSettings from "./settings";

const { therapycare, specialisedcare, advancedcare, covid19, shortstaycare } =
	QuickEdits;

export const editCategories = [
	{
		name: "Care & health services",
		value: "carehealthservices",
		options: [
			{ key: "therapycare", ...therapycare },
			{ key: "specialisedcare", ...specialisedcare },
			{ key: "advancedcare", ...advancedcare },
		]
	},
	{
		name: "Accommodation",
		value: "accommodation",
		options: [{ key: "carebeds", label: "Care beds" }]
	},
	{
		name: "Facililty status",
		value: "facilitystatus",
		options: [{ key: "covid19", ...covid19 }]
	},
	{
		name: "Vacancies",
		value: "vacancies",
		options: [
			{ key: "vacancynotes", label: "Permanent vacancy notes" },
			{ key: "vacancynotesrespite", label: "Respite vacancy notes" }
		]
	},
	{
		name: "Contact",
		value: "contact",
		options: [
			{ key: "phone", label: "Phone (general enquiries)" },
			{ key: "websiteurl", label: "Website URL" },
			{ key: "clinicalcontactuserid", label: "Hospital admissions" },
			{ key: "clinicalemailuserid", label: "Clinical enquiries" },
			{ key: "contactuserid", label: "General enquiries" },
			{ key: "emailuserid", label: "Contact for general enquiry messages" }
		]
	},
	{
		name: "Location",
		value: "location",
		options: [
			{ key: "addressline1", label: "Street address" },
			{ key: "suburb", label: "Suburb" },
			{ key: "postcode", label: "Postcode" }
		]
	},
	{
		name: "Respite",
		value: "shortstaycare",
		options: [{ key: "shortstaycare", ...shortstaycare }]
	},
	{
		name: "Groups and diverse needs",
		value: "diverseneeds",
		options: [
			{
				label: "My facility is culturally-specific",
				key: "culturespecific"
			},
			{
				label: "My facility has a cultural community",
				key: "culturecommunity"
			},
			{
				label: "Culture(s)",
				key: "cultures"
			},
			{
				label: "Language(s)",
				key: "languages"
			},
			{
				label: "Religion(s)",
				key: "religions"
			},
			{
				label: "My facility has a group of 3 or more LGBTI residents",
				key: "lgbti"
			},
			{
				label: "My facility is 'Rainbow Tick' accredited",
				key: "lgbtiaccredited"
			},
			{
				label:
					"My facility includes a war veteran / war widow(er) community of 3 or more people",
				key: "veteranscommunity"
			},
			{
				label: "My facility has group of 3 or more residents who are under 65",
				key: "undersixtyfive"
			},
			{
				label:
					"My facility can support Aboriginal and Torres Strait Islander peoples cultural needs",
				key: "atsi"
			},
			{
				label:
					"My facility can support Aboriginal and Torres Strait Islander peoples cultural needs",
				key: "homeless"
			},
			{
				label:
					"My facility is specifically funded to provide specialist care to people with a history of (or at risk of) homelessness with complex behavioural needs",
				key: "viability"
			},
			{
				label:
					"My facility can support people who are financially or socially disadvantaged",
				key: "disadvantaged"
			}
		]
	},
	{
		name: "Description",
		value: "description",
		options: [
			{ label: "Short summary", key: "shortdescription" },
			{ label: "Detailed description", key: "longdescription" }
		]
	},
	{
		name: "Voluntary Assisted Dying (VAD)",
		value: "vadsupport",
		options: []
	},
	{
		name: "Home care services",
		value: "homecareservices",
		options: []
	}
];

export const editFields = {
	location: {
		type: "TextInput",
		fields: [
			{ type: "InputText", label: "Street address", name: "addressline1" },
			{ type: "InputText", label: "Suburb", name: "suburb" },
			{
				type: "InputText",
				label: "Postcode",
				name: "postcode",
				width: "is-short"
			},
			{
				type: "SelectInput",
				label: "State",
				name: "state",
				options: australianStates
			}
		]
	},
	accommodation: {
		type: "TextInput",
		fields: [{ type: "InputNumber", label: "Number of beds", name: "carebeds" }]
	},
	carehealthservices: {
		type: "CheckBoxSet",
		fields: [
			{
				type: "checkboxset",
				label: "Specialised care",
				name: "specialisedcare",
				options: careTypes.specialised
			},
			{
				type: "checkboxset",
				label: "Complex care",
				name: "advancedcare",
				options: careTypes.advanced
			},
			{
				type: "checkboxset",
				label: "Health and therapy services",
				name: "therapycare",
				options: careTypes.therapyservices
			}
		]
	},
	dedicatedservices: {
		type: "CheckBoxSet",
		fields: [
			{
				type: "checkboxset",
				label: "Dedicated services",
				name: "mentalhealth",
				options: careTypes.mentalhealth
			}
		]
	},
	description: {
		type: "Descriptions",
		fields: [
			{
				type: "InputTextarea",
				label: "Short summary",
				name: "shortdescription",
				width: "is-long",
				maxLength: 250
			},
			{
				type: "InputHTML",
				label: "Detailed description",
				name: "longdescription",
				width: "is-long"
			}
		]
	},
	diverseneeds: {
		type: "textInput",
		fields: [
			{
				type: "InputBoolean",
				label: "My facility is culturally-specific",
				name: "culturespecific"
			},
			{
				type: "InputBoolean",
				label:
					"My facility has a cultural community (3 or more people of the same cultural background)",
				name: "culturecommunity"
			},
			{
				type: "InputTag",
				label: "Culture(s)",
				name: "cultures",
				options: diverseNeeds.cultures,
				default: []
			},
			{
				type: "InputTag",
				label: "Language(s)",
				name: "languages",
				options: diverseNeeds.languages,
				default: []
			},
			{
				type: "InputTag",
				label: "Religion(s)",
				name: "religions",
				options: diverseNeeds.religions,
				default: []
			},
			{
				type: "InputBoolean",
				label: "My facility has a group of 3 or more LGBTI residents",
				name: "lgbti"
			},
			{
				type: "InputBoolean",
				label: "My facility is 'Rainbow Tick' accredited",
				name: "lgbtiaccredited"
			},
			{
				type: "InputBoolean",
				label:
					"My facility includes a war veteran / war widow(er) community of 3 or more people",
				name: "veteranscommunity"
			},
			{
				type: "InputBoolean",
				label: "My facility has group of 3 or more residents who are under 65",
				name: "undersixtyfive"
			},
			{
				type: "InputBoolean",
				label:
					"My facility can support Aboriginal and Torres Strait Islander peoples cultural needs",
				name: "atsi"
			},
			{
				type: "InputBoolean",
				label:
					"My facility can support Aboriginal and Torres Strait Islander peoples cultural needs",
				name: "homeless"
			},
			{
				type: "InputBoolean",
				label:
					"My facility is specifically funded to provide specialist care to people with a history of (or at risk of) homelessness with complex behavioural needs",
				name: "viability"
			},
			{
				type: "InputBoolean",
				label:
					"My facility can support people who are financially or socially disadvantaged",
				name: "disadvantaged"
			}
		]
	},
	facilitystatus: {
		type: "CheckBoxSet",
		fields: [
			{
				type: "checkboxset",
				label: "COVID-19",
				name: "covid19",
				options: lockdown.covid
			}
		]
	},
	shortstaycare: {
		type: "CheckBoxSet",
		fields: [
			{
				type: "checkboxset",
				label: "Short-stay respite options",
				name: "shortstaycare",
				options: respite.shortstay
			}
		]
	},
	vacancies: {
		type: "Descriptions",
		fields: [
			{
				type: "InputTextarea",
				label: "Permanent care note",
				name: "vacancynotes",
				maxLength: 750
			},
			{
				type: "InputTextarea",
				label: "Respite care note",
				name: "vacancynotesrespite",
				maxLength: 750
			}
		]
	},
	contact: {
		type: "TextInput",
		fields: [
			{
				type: "InputText",
				label: "Phone (general enquiries)",
				name: "phone",
				width: "is-short"
			},
			{
				type: "InputText",
				label: "Website URL",
				name: "websiteurl",
				width: "is-long"
			},
			{
				type: "UserSelect",
				label: "Hospital admissions",
				name: "clinicalcontactuserid"
			},
			{
				type: "UserSelect",
				label: "Clinical enquiries",
				name: "clinicalemailuserid"
			},
			{ type: "UserSelect", label: "General enquiries", name: "contactuserid" },
			{
				type: "UserSelect",
				label: "Contact for general enquiry messages",
				name: "emailuserid"
			}
		]
	},
	listingstatus: {
		type: "TextInput",
		fields: [
			// {
			// 	type: "HospitalSelect",
			// 	label: "Default hospital",
			// 	name: "defaulthospitalid"
			// },
			{
				type: "SelectInput",
				label: "Appearance",
				name: "plan",
				options: listingSettings.plans
			},
			{
				type: "SelectInput",
				label: "Listing status",
				name: "status",
				options: listingSettings.status
			}
		]
	},
	vadsupport: {
		type: "RadioSet",
		fields: [
			{
				type: "radioset",
				label: "Voluntary Assisted Dying (VAD)",
				name: "vadsupport",
				options: careTypes.vadSupport
			}
		]
	},
	homecareservices: {
		type: 'textInput',
		fields: [
			{
				type: 'InputBoolean',
				label: 'The organisation provides home care services',
				name: 'hashomecare',
			}
		]
	}
};
