<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="modal" :class="{ 'is-active': active }">
		<div class="modal-background"></div>
		<div class="modal-content">
			<slot></slot>
		</div>
		<button
			class="modal-close is-large"
			aria-label="close"
			@click.prevent="emits('close')"
		></button>
	</div>
</template>

<script lang="ts" setup>
interface propsType {
	active?: boolean;
}

const props = withDefaults(defineProps<propsType>(), {
	active: true
});

const emits = defineEmits<{
	(e: "close"): void;
}>();
</script>

<script lang="ts">
export default {
	name: "AppModalDisplay"
};
</script>
