<template>
	<div class="bulk-edit-bar gtm-bulk-edit-bar w-full">
		<slot></slot>
		<div class="field is-grouped">
			<div class="control">
				<div class="select">
					<select
						v-model="category"
						class="gtm-category"
						data-test="select-category"
					>
						<option :value="undefined">Choose category</option>
						<option
							v-for="(option, index) in categories"
							:key="index"
							:value="option"
						>
							{{ option.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="control">
				<div class="select option_select">
					<select
						v-model="option"
						:disabled="!options"
						class="gtm-options"
						data-test="select-options"
					>
						<option :value="undefined">Edit all options</option>
						<option
							v-for="(option, index) in options"
							:key="index"
							:value="option"
						>
							{{ option.label }}
						</option>
					</select>
				</div>
			</div>
			<div class="control">
				<div class="select">
					<select
						v-model="value"
						:disabled="!values.length"
						class="gtm-value"
						data-test="select-value"
					>
						<option :value="undefined">Choose a value</option>
						<option
							v-for="({ label, value }, index) in values"
							:key="index"
							:value="value"
						>
							{{ label }}
						</option>
					</select>
				</div>
			</div>
			<div>
				<button
					class="button is-info gtm-edit-button"
					:disabled="!canEdit"
					@click.prevent="edit"
					data-test="edit-button"
				>
					Edit
				</button>
			</div>

			<slot name="after" />
		</div>
	</div>
</template>

<script>
import { bulkeditStore } from "@/stores/bulkedit";
import { mapStores } from "pinia";
export default {
	name: "BulkEditBar",
	props: {
		shareLink: {
			type: Boolean,
			default: () => false
		}
	},
	computed: {
		...mapStores(bulkeditStore),
		categories() {
			return this.bulkeditStore.categories;
		},
		options() {
			return this.bulkeditStore.options;
		},
		values() {
			return this.bulkeditStore.values;
		},
		canEdit() {
			return !!this.category && this.editLink;
		},
		category: {
			get() {
				return this.bulkeditStore.category;
			},
			set(category) {
				this.bulkeditStore.$patch({ category });
			}
		},
		option: {
			get() {
				return this.bulkeditStore.option;
			},
			set(option) {
				this.bulkeditStore.$patch({ option });
			}
		},
		value: {
			get() {
				return this.bulkeditStore.value;
			},
			set(value) {
				this.bulkeditStore.$patch({ value });
			}
		},
		editLink() {
			let name = "bulk-edit-category";
			const params = {
				category: this.category.value
			};

			if (this.option) {
				name = "bulk-edit-category-option";
				params.option = this.option.key;
			}
			if (this.value) {
				name = "quick-edit-option-value";
				delete params.category;
				params.option = this.option.key;
				params.value = this.value;
			}
			return { name, params };
		}
	},
	watch: {
		category() {
			this.option = undefined;
			this.value = undefined;
		},
		option() {
			this.value = undefined;
		}
	},
	beforeMount() {
		this.bulkeditStore.$reset();
	},
	methods: {
		edit() {
			this.$router.push(this.editLink);
		}
	}
};
</script>

<style lang="scss">
.bulk-edit-bar {
	margin-bottom: 1em;
	> p {
		line-height: 1;
		margin-bottom: 0.5rem;
	}
}
.option_select {
	max-width: 250px;
}
.value_select {
	min-width: 150px;
}
</style>
