export default {
	cultures: [
		"Acehnese",
		"Acholi",
		"Afghan",
		"African American",
		"Afrikaner",
		"Akan",
		"Albanian",
		"Algerian",
		"American",
		"Amhara",
		"Anglo-Burmese",
		"Anglo-Indian",
		"Angolan",
		"Arab",
		"Argentinian",
		"Armenian",
		"Assyrian",
		"Australian Aboriginal",
		"Australian South Sea Islander",
		"Austrian",
		"Azeri",
		"Bahraini",
		"Balinese",
		"Bangladeshi",
		"Barbadian",
		"Bari",
		"Basque",
		"Batswana",
		"Belarusan",
		"Belgian",
		"Bengali",
		"Berber",
		"Bermudan",
		"Bhutanese",
		"Bolivian",
		"Bosnian",
		"Brazilian",
		"British",
		"Bruneian",
		"Bulgarian",
		"Burgher",
		"Burmese",
		"Burundian",
		"Cameroonian",
		"Canadian",
		"Caribbean Islander",
		"Catalan",
		"Central American",
		"Central and West African",
		"Central Asian",
		"Chaldean",
		"Channel Islander",
		"Chilean",
		"Chin",
		"Chinese",
		"Chinese Asian",
		"Colombian",
		"Congolese",
		"Cook Islander",
		"Coptic",
		"Costa Rican",
		"Croatian",
		"Cuban",
		"Cypriot",
		"Czech",
		"Danish",
		"Darfu/Darfurian",
		"Dinka",
		"Dutch",
		"Eastern European",
		"Ecuadorian",
		"Egyptian",
		"Emirati",
		"Eritrean",
		"Estonian",
		"Ethiopian",
		"Fijian",
		"Fijian Indian",
		"Filipino",
		"Finnish",
		"Flemish",
		"French",
		"French Canadian",
		"Frisian",
		"Fulani",
		"Georgian",
		"German",
		"Ghanaian",
		"Gibraltarian",
		"Gio",
		"Greek",
		"Guatemalan",
		"Gujarati",
		"Guyanese",
		"Hawaiian",
		"Hazara",
		"Hispanic (North American)",
		"Hmong",
		"Hungarian",
		"Hutu",
		"Icelandic",
		"Igbo",
		"I-Kiribati",
		"Indian",
		"Indian Tamil",
		"Indonesian",
		"Iranian",
		"Iraqi",
		"Irish",
		"Italian",
		"Ivorean",
		"Jamaican",
		"Japanese",
		"Javanese",
		"Jewish",
		"Jordanian",
		"Kadazan",
		"Karen",
		"Kashmiri",
		"Kazakh",
		"Kenyan",
		"Khmer (Cambodian)",
		"Korean",
		"Krahn",
		"Kunama",
		"Kurdish",
		"Kuwaiti",
		"Kyrgyz",
		"Lao",
		"Latvian",
		"Lebanese",
		"Liberian",
		"Libyan",
		"Lithuanian",
		"Luxembourg",
		"Macedonian",
		"Madi (Ma'di)",
		"Madurese",
		"Mainland South-East Asian",
		"Malawian",
		"Malay",
		"Malayali",
		"Maldivian",
		"Maltese",
		"Mandaean",
		"Mandinka",
		"Manx",
		"Maori",
		"Maritime South-East Asian",
		"Masai",
		"Mauritian",
		"Mayan",
		"Melanesian and Papuan",
		"Mexican",
		"Micronesian",
		"Moldovan",
		"Mon",
		"Mongolian",
		"Montenegrin",
		"Moroccan",
		"Mozambican",
		"Namibian",
		"Native North American Indian",
		"Nauruan",
		"Nepalese",
		"New Caledonian",
		"New Zealander",
		"Nicaraguan",
		"Nigerian",
		"Niuean",
		"Ni-Vanuatu",
		"North American",
		"Northern European",
		"Norwegian",
		"Nubian",
		"Nuer",
		"Ogaden",
		"Omani",
		"Oromo",
		"Otr Nth African & Mid. Eastern",
		"Pakistani",
		"Palestinian",
		"Papua New Guinean",
		"Paraguayan",
		"Parsi",
		"Pathan",
		"Peoples of the Sudan",
		"Peruvian",
		"Polish",
		"Polynesian",
		"Portuguese",
		"Puerto Rican",
		"Punjabi",
		"Qatari",
		"Rohingya",
		"Roma/Gypsy",
		"Romanian",
		"Russian",
		"Rwandan",
		"Salvadoran",
		"Samoan",
		"Saudi Arabian",
		"Scottish",
		"Senegales",
		"Serbian",
		"Seychellois",
		"Shona",
		"Sierra Leonean",
		"Sikh",
		"Sindhi",
		"Singaporean",
		"Sinhalese",
		"Slovak",
		"Slovene",
		"Solomon Islander",
		"Somali",
		"Sorb/Wend",
		"South African",
		"South American",
		"South Eastern European",
		"South Sudanese",
		"Southern and East African",
		"Southern Asian",
		"Southern European",
		"Spanish",
		"Sri Lankan",
		"Sri Lankan Tamil",
		"Sudanese",
		"Sundanese",
		"Swahili",
		"Swazilander",
		"Swedish",
		"Swiss",
		"Syrian",
		"Tahitian",
		"Taiwanese",
		"Tajik",
		"Tanzanian",
		"Tatar",
		"Telugu",
		"Temoq",
		"Thai",
		"Themne",
		"Tibetan",
		"Tigrayan",
		"Tigre",
		"Timorese",
		"Togolese",
		"Tokelauan",
		"Tongan",
		"Torres Strait Islander",
		"Trinidadian (Tobagonian)",
		"Tunisian",
		"Turkish",
		"Turkmen",
		"Tuvaluan",
		"Ugandan",
		"Uighur",
		"Ukrainian",
		"Uruguayan",
		"Uzbek",
		"Venezuelan",
		"Vietnamese",
		"Vlach",
		"Welsh",
		"Western European",
		"Yemeni",
		"Yoruba",
		"Zambian",
		"Zimbabwean",
		"Zulu"
	],
	languages: [
		"Acholi",
		"Afrikaans",
		"Albanian",
		"Alyawarr (Alyawarra)",
		"Amharic (Ethiopean)",
		"Anyuak/Anuak",
		"Arabic",
		"Arakanese/Rakhinz",
		"Armenian",
		"Arrernte (Aranda)",
		"Asante/Ashanti",
		"Assamese (Indian)",
		"Assyrian (Including Aramaic)",
		"Assyrian",
		"Australian Indigenous Lang",
		"Azari",
		"Azerbaijani",
		"Baluchi/Balochi",
		"Bambara",
		"Bari/Beri",
		"Basque",
		"Bassa",
		"Bemba",
		"Bengali (Indian)",
		"Bisaya",
		"Bislama",
		"Bodo (Indian)",
		"Bosnian",
		"Breton",
		"Bulgarian",
		"Burarra",
		"Byelorussian",
		"Cantonese",
		"Catalan",
		"Cebuano",
		"Chaldean",
		"Chi-Nyanja/Nyanja",
		"Chichewa/Chewa",
		"Chin Haka (Dialect of Chin)",
		"Chin (Indian)",
		"Chinese (All Dialects)",
		"Chizigula/Zigula",
		"Coptic",
		"Croatian",
		"Czech",
		"Dan/Gio",
		"Danish",
		"Dari",
		"Dinka",
		"Divehi",
		"Dogri (Indian)",
		"Dutch",
		"Dzongkha",
		"Eastern Asian Languages",
		"Eastern European Lang",
		"Esperanto",
		"Estonian",
		"Ewe",
		"Falam Chin (Dialect of Chin)",
		"Fanti/Fante",
		"Farsi (Persian)",
		"Fiji Hindi",
		"Fijian",
		"Filipino/Tagalog",
		"Finnish",
		"Flemish",
		"Foochow (Chinese)",
		"French",
		"Fukien (Chinese)",
		"Fulani",
		"Fullah",
		"Fulliru",
		"Fuqing",
		"Fur",
		"Fuzhou (Chinese)",
		"Gaelic (Ireland)",
		"Gaelic (Scotland)",
		"Galician",
		"Gan",
		"Georgian",
		"German",
		"Gilbertese",
		"Gio/Dan",
		"Greek",
		"Gro",
		"Gujarati (Indian)",
		"Hainanese (Chinese)",
		"Haitian",
		"Hakka (Chinese)",
		"Hakka (Timorese)",
		"Harari",
		"Hassani (Dialect of Arabic)",
		"Hassaniya (Dialect of Arabic)",
		"Hasseniyya (Dialect of Arabic)",
		"Hausa",
		"Hawaiian",
		"Hazaragi",
		"Hebrew",
		"Hindi (Indian)",
		"Hindustani/Hindi-Urdu",
		"Hmong",
		"Hokkien (Chinese)",
		"Hungarian",
		"Icelandic",
		"Igbo/Ibo",
		"Ikalanga/Kalanga",
		"Indian (All Dialects)",
		"Indonesian",
		"Irish",
		"Italian",
		"Japanese",
		"Javanese",
		"Juba/Arabic Pidgin",
		"Kachin",
		"Kakwa",
		"Kalentin",
		"Kannada (Indian)",
		"Karen",
		"Karenni/Kayah",
		"Kashmiri (Indian)",
		"Kazakh",
		"Khmer",
		"Khumi (Dialect of Chin)",
		"Ki-Kongo",
		"Ki-Kuyu",
		"Ki-Luba/Luba",
		"Kikuyu",
		"Kingoni",
		"Kinjanda",
		"Kinyarwanda",
		"Kirghiz",
		"Kirundi",
		"Kissi",
		"Kiswahili",
		"Konkani (Indian)",
		"Kono",
		"Korean",
		"Kpelle",
		"Krio",
		"Kriol",
		"Kuanua/Tolai",
		"Kuku",
		"Kunama",
		"Kurdish (Badini)",
		"Kurdish (Faili)",
		"Kurdish (Gorani)",
		"Kurdish (Irani/Kurdistani)",
		"Kurdish (Kurmanji)",
		"Kurdish (Sorani)",
		"Kuurinji (Gurindji)",
		"Kwa",
		"Lao",
		"Latin",
		"Latvian",
		"Liberian Pidgin",
		"Limba",
		"Lingala",
		"Lisu",
		"Lithuanian",
		"Loko",
		"Loma",
		"Lori",
		"Luganda",
		"Luo",
		"Luwa",
		"Macedonian",
		"Madi",
		"Maithili (Indian)",
		"Malagasy",
		"Malayalam (Indian)",
		"Malaysian/Malay/Cocos Malay",
		"Malinka",
		"Maltese",
		"Mandarin",
		"Mandingo",
		"Mano",
		"Maori",
		"Mara (Dialect of Chin)",
		"Marathi (Indian)",
		"Maru",
		"Mashi",
		"Matu (Dialect of Chin)",
		"Mauritian Creole",
		"Meitei (Indian)",
		"Mende",
		"Mina",
		"Mizo Chin (Dialect of Chin)",
		"Moldavian",
		"Mon",
		"Mongolian",
		"Moru",
		"Motu",
		"Mundari",
		"Myanmar (Burmese)",
		"Nauruan",
		"Ndebele/Jindebele",
		"Nepalese (Indian)",
		"Netherlandic",
		"Non Verbal",
		"Northern European Lang",
		"Norwegian",
		"Not Stated/Inadequately Desc",
		"Nubia",
		"Nuer",
		"Nyagwara",
		"Odia (Indian)",
		"Oriya/Odia",
		"Oromo",
		"Other African Languages",
		"Other Asian Languages",
		"Other European Languages",
		"Other Languages",
		"Other Oceanic Languages",
		"Other",
		"Ov-Ambo",
		"Papiamento",
		"Pashtu/Pashto",
		"Pedi/Northern Soto",
		"Pidgin/Neo-Melanesian",
		"Pintupi",
		"Pitjantjatjara",
		"Pojulu",
		"Polish",
		"Portuguese",
		"Pukapuka",
		"Pulaar",
		"Punjabi (Indian)",
		"Pwo Karen (Dialect of Karen)",
		"Quechua",
		"Rajasthani",
		"Rohingya (Myanmar)",
		"Rohinya (Cook Islands)",
		"Romanian",
		"Romansch",
		"Romany",
		"Russian",
		"Saho",
		"Samoan",
		"Sanskrit (Indian)",
		"Santali (Indian)",
		"Sardinian",
		"Serbian",
		"Setswana",
		"Sgaw Karen (Dialect of Karen)",
		"Shan",
		"Shanghainese",
		"Shilenge",
		"Shiluk",
		"Shona/Chishona",
		"Sichuan",
		"Sidamic",
		"Sindhi (Indian)",
		"Sinhalese",
		"Siym (Dialect of Chin)",
		"Slovak",
		"Slovene",
		"Somali",
		"Sotho",
		"Southeast Asian Languages",
		"Southern Asian Languages",
		"Southern European Lang",
		"Spanish",
		"Sudanese Arabic",
		"Sukuma",
		"Susu",
		"Sw Asian/N African Lang",
		"Swahili/Ki-Swahili",
		"Swazi",
		"Swedish",
		"Syriac",
		"Tajik",
		"Tamil (Indian)",
		"Tartar",
		"Tedim Chin (Dialect of Chin)",
		"Telugu (Indian)",
		"Temne",
		"Teo Chiew (Chinese)",
		"Tetum (Timorese)",
		"Thai",
		"Tibetan",
		"Tigre",
		"Tigrinya",
		"Tiv",
		"Tiwi",
		"Tokelauan",
		"Tongan",
		"Tshiluba",
		"Tsonga",
		"Turkish",
		"Turkmen",
		"Twi",
		"Uighur",
		"Ukrainian",
		"Urdu (Indian)",
		"Uzbek",
		"Vietnamese",
		"Walmajarri (Walmadjari)",
		"Warlpiri",
		"Watchi",
		"Welsh",
		"Wendish",
		"Wik-Mungkan",
		"Wolof",
		"Xhosa",
		"Yalunka",
		"Yiddish",
		"Yoruba",
		"Yugoslavian",
		"Zande",
		"Zomi (Dialect of Chin)",
		"Zonot (Dialect of Chin)",
		"Zulu"
	],
	religions: [
		"Aboriginal Evangelical Missions",
		"Agnosticism",
		"Albanian Orthodox",
		"Ancestor Veneration",
		"Ancient Church of the East",
		"Anglican",
		"Anglican Catholic Church",
		"Anglican Church of Australia",
		"Animism",
		"Antiochian Orthodox",
		"Apostolic Church (Australia)",
		"Apostolic Church of Queensland",
		"Armenian Apostolic",
		"Assemblies of God",
		"Assyrian Apostolic",
		"Assyrian Church of the East",
		"Atheism",
		"Australian Aboriginal Traditional Religions",
		"Baha'i",
		"Baptist",
		"Bethesda Churches",
		"Born Again Christian",
		"Brethren",
		"Buddhism",
		"Caodaism",
		"Catholic",
		"Chaldean Catholic",
		"Chinese Religions",
		"Christadelphians",
		"Christian",
		"Christian & Missionary Alliance",
		"Christian City Church",
		"Christian Life Churches International",
		"Christian Outreach Centres",
		"Christian Revival Crusade",
		"Christian Science",
		"Church of Christ (Non-denominational)",
		"Church of Jesus Christ of LDS",
		"Church of Scientology",
		"Church of the Nazarene",
		"Churches of Christ",
		"Churches of Christ (Confirmed)",
		"Community of Christ",
		"Confucianism",
		"Congregational",
		"Coptic Orthodox Church",
		"Druidism",
		"Druse",
		"Eastern Orthodox",
		"Eckankar",
		"Ethiopian Orthodox Church",
		"Ethnic Evangelical Churches",
		"Faith Churches",
		"Foursquare Gospel Church",
		"Free Reformed",
		"Full Gospel Church",
		"Gnostic Christians",
		"Greek Orthodox",
		"Hinduism",
		"Humanism",
		"Independent Evang. Churches",
		"International Church of Christ",
		"Islam",
		"Jainism",
		"Japanese Religions",
		"Jehovah's Witnesses",
		"Judaism",
		"Latter-day Saints",
		"Liberal Catholic Church",
		"Lutheran",
		"Macedonian Orthodox",
		"Maronite Catholic",
		"Melkite Catholic",
		"Nature Religions",
		"New Apostolic Church",
		"New Churches (Swedenborgian)",
		"No religion",
		"Oriental Orthodox",
		"Other Christian",
		"Other Protestant",
		"Paganism",
		"Pantheism",
		"Pentecostal",
		"Presbyterian",
		"Presbyterian and Reformed",
		"Rastafarianism",
		"Ratana (Maori)",
		"Rationalism",
		"Reformed",
		"Religious Science",
		"Religious Society of Friends",
		"Revival Centres",
		"Rhema Family Church",
		"Romanian Orthodox",
		"Russian Orthodox",
		"Salvation Army",
		"Satanism",
		"Serbian Orthodox",
		"Seventh-day Adventist",
		"Shinto",
		"Sikhism",
		"Spiritualism",
		"Sukyo Mahikari",
		"Syrian Orthodox Church",
		"Taoism",
		"Temple Society",
		"Tenrikyo",
		"Theosophy",
		"Ukrainian Catholic",
		"Ukrainian Orthodox",
		"Unitarian",
		"United Pentecostal",
		"Uniting Church",
		"Wesleyan Methodist Church",
		"Western Catholic",
		"Wiccan/Witchcraft",
		"Worldwide Church of God",
		"Zoroastrianism"
	]
};
