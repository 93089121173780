<script lang="ts">

</script>

<script lang="ts" setup>

</script>

<template>
  <h2 class="text-5xl font-medium"><slot /></h2>
</template>
