import { userStore } from "@/stores/user";
// import type { Listing } from "@/types/listing";
import { defineStore } from "pinia";
import { sortBy } from "lodash";
import { editCategories } from "@/meta/listing/bulkedit";
import type { Listing } from "@/types/listing";
import * as api from "@/api/bulk.edits.api";

interface Category_Option {
	key: string;
	label: string;
	options: any;
}

interface Category {
	name: string;
	value: string;
	options: Category_Option[];
}

interface BulkEditState {
	listings: Listing[];
	category: Category | undefined;
	option: Category_Option | undefined;
	value: string | undefined;
}

export const state = (): BulkEditState => ({
	listings: [],
	category: undefined,
	option: undefined,
	value: undefined
});

export const bulkeditStore = defineStore("bulkedit", {
	state,
	getters: {
		categories: (): Category[] => sortBy(editCategories, "name") as Category[],
		options: (state) => {
			return state.category ? state.category.options : undefined;
		},
		values: (state) => {
			if (state.option) {
				const { options } = state.option;
				return options
					? Object.entries(options).map(([value, label]) => {
							return {
								value,
								label
							};
					  })
					: [];
			}
			return [];
		},
		organisation: () => userStore().organisation
	},
	actions: {
		updateListing({
			listingid = 0,
			name = "",
			option = ""
		}: {
			listingid: number;
			name: string;
			option: string | string[];
		}) {
			if (listingid) {
				const listing = this.listings.find((l) => l.id === listingid);
				if (listing) {
					listing[name] = option;
				}
			}
		},
		updateAllColumns(name: string, option: string) {
			this.listings
				.filter((listing: Listing) => {
					if (Array.isArray(listing[name])) {
						return !listing[name].includes(option);
					}

					return listing[name] !== option;
				})
				.forEach((listing) => {
					if (Array.isArray(listing[name])) {
						return listing[name].push(option);
					}
					listing[name] = option;
				});
		},
		updateColumn(name: string, option: string) {
			this.listings.forEach((listing) => {
				listing[name] = option;
			});
		},
		removeAllColumns(name: string, option: string) {
			this.listings.forEach((listing) => {
				if (Array.isArray(listing[name])) {
					const filtered = listing[name].filter(
						(item: string) => item !== option
					);
					return (listing[name] = filtered);
				}
				listing[name] = option;
			});
		},
		async saveChanges() {
			await api.updateList(
				this.organisation?.id || 0, // organisation id
				this.listings
			);
		}
	}
});
