export default {
	covid: [
		"COVID-19 vaccine"
		// "No visits",
		// "Compassionate / palliative visits",
		// "Window visits",
		// "Video / phone visits",
		// "Visits limited to specific areas",
		// "Case-by-case tours, following Gov. advice",
		// "Video tours only",
		// "Offsite meetings available",
		// "Tours req. screening & restrictions",
		// "Isolation mandatory on admission",
		// "No isolation with negative COVID-19 test",
		// "Can manage residents who can’t be isolated",
		// "Mandatory COVID-19 test on admission",
		// "Isolation until COVID-19 test result",
		// "Accepting COVID-19 recovery (negative test) permanent admissions",
		// "Accepting COVID-19 recovery (negative test) respite admissions"
	],
	visits: [
		{
			value: "visitsaccepting",
			label: "No visits",
			metatag: "Exclude facilities not accepting visits"
		},
		{
			value: "visitscompassionate",
			label: "Compassionate / palliative visits"
		},
		{ value: "visitswindow", label: "Window visits" },
		{ value: "visitsvideo", label: "Video / phone visits" },
		{ value: "visitslimited", label: "Visits limited to specific areas" }
	],
	sitetours: [
		{
			value: "sitetoursbycase",
			label: "Case-by-case tours, following Gov. advice"
		},
		{ value: "sitetoursvideo", label: "Video tours only" },
		{ value: "sitetoursoffsite", label: "Offsite meetings available" },
		{
			value: "sitetoursrestricted",
			label: "Tours req. screening & restrictions"
		}
	],
	isolation: [
		{ value: "isolationmandatory", label: "Isolation mandatory on admission" },
		{
			value: "isolationnotrequired",
			label: "No isolation with negative COVID-19 test"
		},
		{
			value: "isolationmanagement",
			label: "Can manage residents who can’t be isolated"
		},
		{
			value: "isolationcovidtestmandatory",
			label: "Mandatory COVID-19 test on admission"
		},
		{
			value: "isolationuntilcovidresult",
			label: "Isolation until COVID-19 test result"
		}
	],
	admissions: [
		{
			value: "acceptcovidrecoveryperm",
			label: "Accepting COVID-19 recovery (negative test) permanent admissions"
		},
		{
			value: "acceptcovidrecoveryrespite",
			label: "Accepting COVID-19 recovery (negative test) respite admissions"
		}
	],
	lockdown: ["Facility is in lockdown"]
};
