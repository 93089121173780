<template>
	<span :class="`tag is-${tagType}`">
		<slot><span>{{ timesince }}</span></slot>
	</span>
</template>

<script>
import { isToday, isYesterday, differenceInCalendarDays, isBefore } from "date-fns";
import { date_local } from "@/plugins/format";

export default {
	name: "AppUpdatedTag",
	props: {
		created: {
			type: Date,
			default: () => new Date()
		},
		lastSeen: {
			type: Date,
			default: () => new Date()
		}
	},
	computed: {
		tagType() {
			const since = differenceInCalendarDays(new Date(), this.lastSeen);

			return since <= 7
				? "success"
				: since > 7 && since <= 30
				? "warning"
				: "danger";
		},
		timesince() {
			// if the last seen is before the created date, then it's never been seen
			if (isBefore(this.lastSeen, this.created)) {
				return "Never seen";
			}

			if (isToday(this.lastSeen)) {
				return `Today`;
			}
			if (isYesterday(this.lastSeen)) {
				return `Yesterday`;
			}
			return date_local(this.lastSeen);
		}
	},
	methods: {}
};
</script>

<style scoped>
.tag {
	min-width: 4.6875rem;
}
</style>
