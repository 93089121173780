<script lang="ts">

</script>

<script lang="ts" setup>

</script>

<template>
  <div class="py-12 px-16 bg-gradient-to-r from-dc-blue-400 via-dc-blue-400 to-dc-blue-500 rounded-2xl text-white shadow relative">
    <slot />
  </div>
</template>
