export const australianStates = [
	{
		value: "NSW",
		label: "New South Wales"
	},
	{
		value: "VIC",
		label: "Victoria"
	},
	{
		value: "QLD",
		label: "Queensland"
	},
	{
		value: "WA",
		label: "Western Australia"
	},
	{
		value: "SA",
		label: "South Australia"
	},
	{
		value: "TAS",
		label: "Tasmania"
	},
	{
		value: "ACT",
		label: "Australian Capital Territory"
	},
	{
		value: "NT",
		label: "Northern Territory"
	}
];
