<template>
	<form action="">
		<form-field>
			<div class="field has-addons">
				<p class="control">
					<button class="button select-all" @click.prevent="selectAll">
						Select all
					</button>
				</p>
				<p class="control">
					<button class="button clear-all" @click.prevent="clearAll">
						Clear all
					</button>
				</p>
			</div>
			<ul>
				<li v-for="{ title, id } in listings" :key="id" class="mb-1">
					<label :for="`listing-${id}`" class="checkbox">
						<input
							type="checkbox"
							v-model="data.listingids"
							:value="id"
							:id="`listing-${id}`"
						/>
						{{ title }}
					</label>
				</li>
			</ul>
		</form-field>
		<form-field label="PDF layout" class="mt-5">
			<p
				v-for="{ value, label } in pdfOptions"
				:key="value"
				class="inline-block mr-3"
			>
				<label class="radio">
					<input
						type="radio"
						v-model="data.type"
						:value="value"
						:data-test="`field-category-${value}`"
					/>
					{{ label }}
				</label>
			</p>
		</form-field>
		<div class="buttons mt-5">
			<button
				type="submit"
				class="button is-primary is-medium gtm-generate-pdf"
				:disabled="!data.listingids.length"
				@click.prevent="submit"
			>
				Save PDF</button
			><a class="button is-outlined is-medium" @click.prevent="cancel"
				>Cancel</a
			>
		</div>
	</form>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { listingsStore } from "@/stores/listings";
import { vacanciesStore } from "@/stores/vacancies";
import { storeToRefs } from "pinia";
import { userStore } from "@/stores/user";

const { modal } = storeToRefs(vacanciesStore());
const { exportPDF } = vacanciesStore();
const { listings } = storeToRefs(listingsStore());
const { organisation } = storeToRefs(userStore());

interface FormType {
	listingids: number[];
	type: string;
}

const pdfOptions = [
	{ value: "Standard", label: "Standard" },
	{ value: "Compact", label: "Compact" }
];

const data = reactive<FormType>({
	listingids: [],
	type: "Standard"
});

const clearAll = () => (data.listingids = []);
const selectAll = () => (data.listingids = listings.value.map((l) => l.id));

const submit = () => {
	if (organisation.value) {
		exportPDF(organisation.value.id, data.listingids, data.type);
	}
};
const cancel = () => (modal.value = false);
</script>

<script lang="ts">
export default {
	name: "FormVacanciesExport"
};
</script>
