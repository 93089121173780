export default {
	shortstay: [
		"Day program",
		"Dedicated respite room(s)",
		"Emergency respite",
		"Non-ACAT respite accepted",
		"Non-weight bearing",
		"Planned respite",
		"Respite in secure dementia care"
	],
	feePolicy: [
		{
			value: "pre-payment",
			label: "On admission or soon after",
		},
		{
			value: "post-payment",
			label: "On departure",
		},
	]
};
