<script lang="ts">

</script>

<script lang="ts" setup>

</script>

<template>
  <div class="w-1/2 flex flex-col items-start gap-12">
    <slot />
  </div>
</template>
