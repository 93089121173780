<script lang="ts">
import type { Listing } from '@/types/listing';

export interface VacancyExportBtnProps {
  listings: Listing[]
}
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia';
import { organisationStore } from '@/stores/organisation';
import { vacanciesStore } from '@/stores/vacancies';

const props = defineProps<VacancyExportBtnProps>()

const { organisation } = storeToRefs(organisationStore())
const { modal } = storeToRefs(vacanciesStore());
const { exportPDF } = vacanciesStore();

const showChanger = computed(() => props.listings.length > 1);

function savePDF () {
  if (!organisation.value) {
    console.error('Attempted to export vacancies without an organisation');
    return
  }

	exportPDF(organisation.value.id, props.listings.map((l) => l.id));
}
</script>

<template>
  <button v-if="showChanger" class="button gtm-save-as-pdf" @click="modal = true">
    <i class="fal fa-file-pdf mr-2"></i>
    <span>Export vacancies</span>
  </button>

  <!-- If user only has access to one listing, skip print modal with listing selection and go straight to savePDF -->
  <button v-else class="button gtm-save-as-pdf" @click="savePDF">
    <i class="fal fa-file-pdf mr-2"></i>
    <span>Save as PDF</span>
  </button>
</template>
