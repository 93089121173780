<template>
	<div class="mb-3 last:mb-0" :class="{ field__error: inValid }">
		<label class="block mb-2 font-bold">{{ label }}</label>
		<div class="control" :class="{ 'has-icons-left': icon }">
			<slot></slot>
			<span v-if="icon" class="icon is-small is-left">
				<i class="fas" :class="icon"></i>
			</span>
		</div>
		<p v-if="inValid" class="text-red-600 text-sm mt-1">
			<span
				v-for="error in errors"
				:key="error.$uid"
				:data-test="`${error.$property}-error`"
			>
				{{ error.$message.replace("Value", label) }}<br />
			</span>
		</p>
	</div>
</template>

<script>
export default {
	name: "FormFieldHolder",
	props: {
		errors: {
			type: Array,
			default: () => []
		},
		label: {
			type: String,
			default: () => null
		},
		icon: {
			type: String,
			default: () => undefined
		}
	},
	computed: {
		inValid() {
			return this.errors.length >= 1;
		}
	}
};
</script>

<style lang="postcss">
.field__error input[type="text"],
.field__error input[type="number"],
.field__error input[type="password"] {
	@apply border-red-500;
}
</style>
