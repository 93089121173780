export default {
	advanced: [
		"Auslan (sign language)",
		"Challenging behaviours",
		"General mental health",
		"Huntington's disease",
		"Jejunostomy feeding",
		"Motor neurone disease",
		"Multiple sclerosis (MS)",
		"Nasogastric feeding",
		"Peg feeds",
		"Peri dialysis",
		"Physiotherapy (on-site)",
		"Power wheelchair",
		"Spinal care",
		"Tracheotomy"
	],
	agestage: ["Permanent care", "Ageing in place", "Low care", "High care"],
	categories: ["Permanent care", "Respite care"],
	mentalhealth: ["Geriatric psychiatry unit", "Palliative care unit"],
	specialised: [
		"Dementia support",
		"Dementia high care (integrated)",
		"Secure dementia care",
		"Bariatric care",
		// "Palliative care",
		"Post-operative care",
		"Transition care",
		"Facility is dementia specific (All residents have a form of dementia)"
	],
	shortstay: [
		"Day program",
		"Dedicated respite room(s)",
		"Emergency respite",
		"Non-ACAT respite accepted",
		"Non-weight bearing",
		"Planned respite",
		"Respite in secure dementia care"
	],
	therapyservices: [
		"Dental",
		"General practitioners",
		"Massage",
		"Nurse on call",
		"Optometry",
		"Physiotherapy",
		"Podiatry",
		"Psychiatry",
		"Psychology",
		"Reflexology",
		"Speech pathology"
	],
	provideSecureDementiaCare: [
		{
			type: "limited",
			label: "Limited to some rooms and shared spaces"
		},
		{
			type: "acrossall",
			label: "Across all rooms and shared spaces"
		}
	],
	vadSupport: [
		{
			value: "none",
			label: "None",
			description: "No support for VAD"
		},
		{
			value: "respected",
			label: "Respects access to VAD",
			description:
				"If they choose to, residents can self-administer VAD or be supported by an external practitioner on-site"
		},
		{
			value: "facilitated",
			label: "Can facilitate VAD",
			description: "Active support and assistance for VAD is available on-site"
		}
	]
};
