<template>
	<app-modal :active="modal" @close="close" data-test="app-modal-export">
		<header class="modal-card-head">
			<p class="modal-card-title">Save a vacancies PDF document</p>
			<button aria-label="close" class="delete" @click="close"></button>
		</header>
		<section class="modal-card-body">
			<div class="content">
				<p>
					Save a printable PDF document with current vacancies to that you can
					distribute your resident referral channels.
				</p>
				<p>
					As a
					<strong>Vacancy Editor</strong>, you have access to the following
					residential aged care listings.
				</p>
			</div>
			<form-vacancies-export
				data-test="form-vacancies-export"
			></form-vacancies-export>
		</section>
	</app-modal>
</template>

<script lang="ts" setup>
import { vacanciesStore } from "@/stores/vacancies";
import { storeToRefs } from "pinia";

const { modal } = storeToRefs(vacanciesStore());
//
const close = () => (modal.value = false);
</script>

<script lang="ts">
export default {
	name: "VacanciesExportModal"
};
</script>

<style scoped></style>
