<script lang="ts">
import type { Listing, VacancyType } from '@/types/listing';
import type { UseDatatableOptions } from '@dailycare-au/visualise';
import UpdateTag from '../App/UpdateTag.vue';
import { generateListingSummary } from '@/stores/vacancies';

export interface ListingDatatableProps {
	listings: Listing[]
	vacancies: VacancyType[]
}

type ListingSummary = ReturnType<typeof generateListingSummary>[number]
</script>

<script lang="ts" setup>
import { createColumnHelper } from '@tanstack/vue-table';
import { computed, h } from 'vue';
import BooleanBadge from '@/components/App/BooleanBadge.vue';
import { RouterLink } from 'vue-router';
import { Datatable } from '@dailycare-au/visualise';

const props = defineProps<ListingDatatableProps>()

// rather than use the vacancies summary, we'll generate our own
// so the parent component can pass in whatever vacancies and listings it wants to display
const mappedData = computed(() => generateListingSummary(props.listings, props.vacancies))

const helper = createColumnHelper<ListingSummary>()
const listingsColumns = [
	helper.accessor('listing.title', { id: 'title', header: 'Facility', cell: ({ row, getValue }) => h(RouterLink, { to: { name: 'listings-facility-id', params: { id: row.original.listing.id } } }, getValue) }),
	helper.accessor('listing.suburb', { id: 'suburb', header: 'Location', cell: ({ row, getValue }) => h('span', `${getValue()}, ${row.original.listing.state}`) }),
	helper.accessor('permanentVacancies', { id: 'permanent', header: 'Permanent available', cell: ({ getValue }) => h(BooleanBadge, { value: !!getValue() }) }),
	helper.accessor('expectedVacancies', { id: 'expected', header: 'Permanent expected', cell: ({ getValue }) => h(BooleanBadge, { value: !!getValue() }) }),
	helper.accessor('respiteVacancies', { id: 'respite', header: 'Respite available', cell: ({ getValue }) => h(BooleanBadge, { value: !!getValue() }) }),
	helper.accessor('listing.updated', { id: 'listingUpdated', header: 'Listing details last updated', cell: ({ row, getValue }) => h(UpdateTag, { created: new Date(row.original.listing.created), lastSeen: getValue() }) }),
	helper.accessor('updated', { id: 'vacanciesUpdated', header: 'Vacancy details last updated', cell: ({ row, getValue }) => getValue() && h(UpdateTag, { created: new Date(row.original.listing.created), lastSeen: getValue() }) }),
]

const options: UseDatatableOptions<ListingSummary> = {
	defaults: {
		sorting: [{ id: 'title', desc: false }],
		pagination: { pageIndex: 0, pageSize: 100 }
	}
}
</script>

<template>
  <Datatable :columns="listingsColumns" :data="mappedData" :options="options" />
</template>
