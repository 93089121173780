export default {
	advancedcare: {
		label: "Complex care",
		options: {
			"auslan-sign-language": "Auslan (sign language)",
			"challenging-behaviours": "Challenging behaviours",
			"general-mental-health": "General mental health",
			"jejunostomy-feeding": "Jejunostomy feeding",
			"motor-neurone-disease": "Motor neurone disease",
			"multiple-sclerosis-ms": "Multiple sclerosis (MS)",
			"nasogastric-feeding": "Nasogastric feeding",
			"peg-feeds": "Peg feeds",
			"peri-dialysis": "Peri dialysis",
			"physiotherapy-on-site": "Physiotherapy (on-site)",
			"power-wheelchair": "Power wheelchair",
			"spinal-care": "Spinal care",
			tracheotomy: "Tracheotomy"
		}
	},
	specialisedcare: {
		label: "Specialised care",
		options: {
			"dementia-support": "Dementia support",
			"dementia-high-care-(integrated)": "Dementia high care (integrated)",
			"secure-dementia-care": "Secure dementia care",
			"bariatric-care": "Bariatric care",
			"post-operative-care": "Post-operative care",
			"transition-care": "Transition care",
			"facility-is-dementia-specific":
				"Facility is dementia specific (All residents have a form of dementia)"
		}
	},
	therapycare: {
		label: "Health and therapy services",
		options: {
			dental: "Dental",
			"general-practitioners": "General practitioners",
			massage: "Massage",
			"nurse-on-call": "Nurse on call",
			optometry: "Optometry",
			physiotherapy: "Physiotherapy",
			podiatry: "Podiatry",
			psychiatry: "Psychiatry",
			psychology: "Psychology",
			reflexology: "Reflexology",
			"speech-pathology": "Speech pathology"
		}
	},
	mentalhealth: {
		label: "Dedicated services",
		options: {
			"geriatric-psychiatry-unit": "Geriatric psychiatry unit",
			"palliative-care-unit": "Palliative care unit"
		}
	},
	shortstaycare: {
		label: "Short stay",
		options: {
			"day-program": "Day program",
			"dedicated-respite-rooms": "Dedicated respite room(s)",
			"emergency-respite": "Emergency respite",
			"non-acat-respite-accepted": "Non-ACAT respite accepted",
			"non-weight-bearing": "Non-weight bearing",
			"planned-respite": "Planned respite",
			"respite-in-secure-dementia-care": "Respite in secure dementia care"
		}
	},
	covid19: {
		label: "COVID-19",
		options: {
			"covid-19-vaccine": "COVID-19 vaccine"
		}
	}
};
