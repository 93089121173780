import type { Listing, VacancyType } from "@/types/listing";
import { lastUpdate, vacancyTally } from "@/plugins/vacancies";
import { listingsStore as useListingsStore } from "./listings";
import { ParseFail } from "@/models/parseFail";
import { defineStore, storeToRefs } from "pinia";
import { listing } from "@/api/listing.api";
import { useToast } from "vue-toastification";
import { permanentTypes, respiteTypes } from "@/meta/vacancy";
import { orderBy } from "lodash";
import { SortDef, SortDirection } from '@/components/Table/types'
import { computed, ref } from 'vue';

const toast = useToast();

export function generateListingSummary(listings: Listing[], vacancies: VacancyType[]) {
	const permanentVacancies = vacancies.filter(v => permanentTypes.includes(v.accomodation));
	const respiteVacancies = vacancies.filter(v => respiteTypes.includes(v.accomodation));

	return listings.map((listing) => {
		const { id } = listing;
		return {
			id,
			listing,
			updated: lastUpdate(permanentVacancies, id),
			permanentVacancies: vacancyTally(permanentVacancies, id),
			respiteVacancies: vacancyTally(respiteVacancies, id),
			expectedVacancies: vacancyTally(permanentVacancies, id, "expected"),
		};
	});
}

export const vacanciesStore = defineStore('vacancies', () => {
	const vacancies = ref<VacancyType[]>([]);
	const modal = ref(false);
	const sort = ref<SortDef>({ name: "title", direction: SortDirection.ASC });

	const listingsStore = useListingsStore();
	const { actions, listings } = storeToRefs(listingsStore)

	const permanentVacancies = computed(() => vacancies.value.filter(v => permanentTypes.includes(v.accomodation)));
	const respiteVacancies = computed(() => vacancies.value.filter(v => respiteTypes.includes(v.accomodation)));

	const summary = computed(() => generateListingSummary(listings.value, vacancies.value))

	const oldTableSummary = computed(() => {
		const mapped = summary.value.map((summary) => {
			const { id, title, suburb, state } = summary.listing;
			const { permanentVacancies, respiteVacancies, expectedVacancies, updated } = summary;
			return {
				id,
				title,
				suburb,
				state,
				available: permanentVacancies,
				expected: expectedVacancies,
				respite: respiteVacancies,
				updated
			}
		})

		return orderBy(mapped, [sort.value.name], [sort.value.direction])
	})

	async function setAllCurrent(userId: number) {
		const results = await listing.vacancies.setAllCurrent(userId);

		if (!ParseFail.isFailure(results)) {
			const { listingsReconfirmed: count, actions: newActions } = results;
			// Update the listings in the listing store
			actions.value = newActions;
			// We need to update vacancy.updated manually...
			vacancies.value = vacancies.value.map(v => ({ ...v, updated: new Date() }))
			listingsStore.setVacanciesUpdated(new Date());

			return toast.success(`All vacancies have been reconfirmed for ${count} listings`);
		}

		return toast.error(`Could not reconfirm listings at this time`);
	}

	async function exportPDF(orgId: number, listingids: number[], type = "Standard") {
		await listing.vacancies.vacancyPDF(orgId, listingids, type.toLowerCase());

		// Inform the user something has happened.
		toast.success("Your PDF is being generated and will be emailed to you shortly");
		// now its complete we can close the modal from here.
		modal.value = false;
	}

	function updateVacancy(vacancy: VacancyType) {
		const index = vacancies.value.findIndex((v) => v.id === vacancy.id);
		if (index > -1) {
			vacancies.value[index] = vacancy;
			listingsStore.setListingVacanciesUpdated(vacancy.listingid, new Date());
		}
	}

	return {
		vacancies,
		modal,
		sort,
		permanentVacancies,
		respiteVacancies,
		summary,
		oldTableSummary,
		setAllCurrent,
		exportPDF,
		updateVacancy
	}
})
