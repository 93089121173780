export const categories = [
	{
		value: "Residential aged care",
		label: "Government-funded Residential Aged Care",
		description: ""
	},
	{
		value: "SRS/SRF",
		label: "Supported Residential Services / Supported Residential Facility",
		description: ""
	}
];

export const plans = [
	{
		value: "Basic",
		label: "Basic",
		description: ""
	},
	{
		value: "Trial",
		label: "Trial",
		description: ""
	},
	{
		value: "Active non-subscriber",
		label: "Active non-subscriber",
		description: ""
	},
	{
		value: "Subscription level 1",
		label: "Subscription level 1",
		description: ""
	},
	{
		value: "Subscription level 2",
		label: "Subscription level 2",
		description: ""
	},
	{
		value: "Subscription level 3",
		label: "Subscription level 3",
		description: ""
	}
];

export const raddisplay = [
	{ value: "Show RADs for all rooms", label: "Show RADs for all rooms" },
	{ value: "Only show the lowest RAD", label: "Only show the lowest RAD" },
	{ value: "Only show the highest RAD", label: "Only show the highest RAD" },
	{ value: "Do not show any RADs", label: "Do not show any RADs" }
];

export const status = [
	{
		value: "active",
		label: "Listed"
	},
	{
		value: "non-active",
		label: "Unlisted"
	}
];

export default { categories, plans, status };
