import type { ListingsCollection } from "@/models/listings/collection";
import { Listing, ListingsActions } from "@/types/listing";
import { defineStore } from "pinia";
import { ParseFail } from "@/models/parseFail";
import { SortDef, SortDirection } from "@/components/Table/types";
import { computed, ref } from 'vue'

const PAID_PLANS = [
	'Subscription level 1',
	'Subscription level 2',
	'Subscription level 3',
	'Trial',
]

export const listingsStore = defineStore('listings', () => {
	const listings = ref<Listing[]>([])
	const actions = ref<ListingsActions>({ canReconfirmAll: false })
	const sort = ref<SortDef>({ name: 'title', direction: SortDirection.ASC })

	const notifications = computed(() => {
		const notifications = listings.value
			.filter((listing) => listing.notification)
			.map((listing) => {
				return {
					title: listing.title,
					params: { id: listing.id }
				}
			})
		return notifications
	})

	const fullPlanListings = computed(() => listings.value.filter(l => PAID_PLANS.includes(l.plan)))
	const basicPlanListings = computed(() => listings.value.filter(l => !PAID_PLANS.includes(l.plan)))

	const listingsCount = computed(() => listings.value.length)
	const fullPlanCount = computed(() => fullPlanListings.value.length)
	const basicPlanCount = computed(() => basicPlanListings.value.length)

	const set = (collection: ListingsCollection | ParseFail) => {
		if (!ParseFail.isFailure(collection)) {
			listings.value = collection.entries
			actions.value = collection.actions
		}
	}

	function setVacanciesUpdated(date: Date) {
		listings.value = listings.value.map((listing) => ({ ...listing, vacanciesupdated: date }))
	}

	function setListingVacanciesUpdated(listingId: number, date: Date) {
		const index = listings.value.findIndex((l) => l.id === listingId)
		if (index > -1) {
			listings.value[index].vacanciesupdated = date
		}
	}

	function updateListing(listing: Listing) {
		const index = listings.value.findIndex((l) => l.id === listing.id)
		if (index > -1) {
			listings.value[index] = listing
		}
	}

	return {
		listings,
		actions,
		sort,
		notifications,
		fullPlanListings,
		basicPlanListings,
		listingsCount,
		fullPlanCount,
		basicPlanCount,
		set,
		setVacanciesUpdated,
		setListingVacanciesUpdated,
		updateListing
	}
})